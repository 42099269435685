import React, { FC, ReactNode } from 'react';
import { ContextMenu, Icon } from '@kontentino/ui';
import { useActions } from 'app/hooks/useActions';
import { ActionContextMenuItem } from '../actions/ActionContextMenuItem';
import { ListPost } from 'types/PostDetail';
import { useTranslation } from 'react-i18next';
import { faArrowDownToLine } from '@fortawesome/pro-regular-svg-icons/faArrowDownToLine';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { getMediaDownloadSizes } from 'app/modules/posts/utils/getMediaDownloadSizes';

export type PostContextMenuProps = {
  post: Pick<ListPost, 'id' | 'type' | 'preview_image'>;
  children: ReactNode;
};

export const PostContextMenu: FC<PostContextMenuProps> = ({
  post,
  children,
}) => {
  const mediaDownloadSizes = getMediaDownloadSizes(post.type);
  const { t } = useTranslation();
  const { postActions } = useActions('post-card');

  return (
    <ContextMenu.Root>
      <ContextMenu.Trigger>{children}</ContextMenu.Trigger>
      <ContextMenu.Portal>
        <ContextMenu.Content className="tw-z-[1]">
          <ActionContextMenuItem
            action={postActions.edit}
            actionParams={{ id: post.id }}
          />
          <ActionContextMenuItem
            action={postActions.duplicate}
            actionParams={{ id: post.id }}
          />
          <ActionContextMenuItem
            action={postActions.copyLink}
            actionParams={{ id: post.id }}
          />
          <ActionContextMenuItem
            action={postActions.shareExternally}
            actionParams={{ id: post.id }}
          />
          <ActionContextMenuItem
            action={postActions.move}
            actionParams={{ id: post.id }}
          />
          <ContextMenu.Sub>
            <ContextMenu.SubTrigger
              disabled={!postActions.downloadAssets.isApplicable(post)}
            >
              <ContextMenu.Item>
                <Icon icon={faArrowDownToLine} />
                {t('downloadPostAssets')}
                <Icon size={12} icon={faChevronRight} className="tw-ml-auto" />
              </ContextMenu.Item>
            </ContextMenu.SubTrigger>
            <ContextMenu.SubContent className="tw-z-10 tw-w-56">
              {mediaDownloadSizes.map((size, index) => (
                <ContextMenu.Item
                  key={`download-options-${index}`}
                  onClick={() =>
                    postActions.downloadAssets.perform({
                      id: post.id,
                      size: size.value,
                    })
                  }
                >
                  <Icon icon={faArrowDownToLine} />
                  {t(size.tKey)}
                </ContextMenu.Item>
              ))}
            </ContextMenu.SubContent>
          </ContextMenu.Sub>
          <ActionContextMenuItem
            separator={{ before: true }}
            action={postActions.changeStatus}
            actionParams={{ id: post.id }}
          />
          <ActionContextMenuItem
            separator={{ before: true }}
            action={postActions.delete}
            actionParams={{ id: post.id }}
          />
        </ContextMenu.Content>
      </ContextMenu.Portal>
    </ContextMenu.Root>
  );
};
